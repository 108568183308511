<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					{{ $t("getAllCompanies") }}
				</AppText>
			</div>
		</div>

		<div class="gridForSection mb-20">
			<!-- <app-switch
				style="margin-right: 10px"
				v-model="pageConfig.filters.isAny"
				falseName="Все"
				trueName="Организации имеющий товар"
			/> -->
			<div>
				<form-label
					componentType="dropdown"
					:list="regionsList"
					translatable
					v-model="pageConfig.filters.regionId"
					searchable
					@onSearch="searchRegion"
					:placeholder="$t('region')"
				/>
			</div>
			<div>
				<app-multiple-select
					:list="districtsList"
					itemValue="value"
					itemText="name"
					translatable
					:multiple="true"
					searchable
					:single-item="true"
					@change="pickDistricts($event)"
					@onSearch="searchDistrict"
				/>
				<!-- <form-label
					componentType="dropdown"
					translatable
					:list="districtsList"
					searchable
					@onSearch="searchDistrict"
					v-model="pageConfig.filters.districtIds"
[]="$t('district')"
				/> -->
			</div>
			<div class="section__top-search">
				<app-input
					v-model="pageConfig.filters.tin"
					class="auction-header__input filter__search"
					placeholder="Поиск по ИНН"
				>
					<template #prepend>
						<img src="@/assets/icons/search.svg" style="width: 16px" alt="" />
					</template>
				</app-input>
			</div>

			<AppButton theme="gray-light" sides="20" height="40" @click="search">
				<img src="../../assets/icons/search-gray.svg" alt="repeat" />
			</AppButton>

			<AppButton theme="info" sides="20" height="40" @click="repeat">
				<img src="../../assets/icons/repeat.svg" alt="repeat" />
			</AppButton>

			<AppButton
				theme="info"
				sides="20"
				height="40"
				@click="downloadExcel"
				style="background-color: #e3f8ed; margin-right: 10px"
			>
				<img src="../../assets/icons/excel.svg" alt="excel" />
			</AppButton>
		</div>

		<div class="section__body" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<div class="table-block" :class="isMobileMedium ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr>
							<th class="text-bold" rowspan="2">{{ $t("organizationName") }}</th>
							<th class="text-bold" rowspan="2">{{ $t("inn") }}</th>
							<th class="text-bold" rowspan="2">{{ $t("registrationDate") }}</th>
							<th class="text-bold" rowspan="2">{{ $t("region") }}</th>
							<th class="text-bold" rowspan="2">{{ $t("district") }}</th>
							<th class="text-bold" rowspan="2">{{ $t("typeOrganization") }}</th>
							<th class="text-bold" rowspan="2">{{ $t("providerOrCustomer") }}</th>
							<!-- <th class="text-bold" rowspan="2">{{ $t("suppliers") }}</th> -->
							<th class="text-bold" style="max-width: 300px" colspan="2">
								{{ $t("numberOfOffersByCorp") }}
							</th>
                            <th class="text-bold" rowspan="2">{{ $t("dealer") }}</th>
                            <th class="text-bold" style="max-width: 300px" colspan="2">
								{{ $t("numberOfOffersByDiler") }}
							</th>
							<!-- <th class="text-bold" rowspan="2">{{ $t("participantType") }}</th> -->
							<!-- <th class="text-bold">{{ $t("manufacturer") }}</th>
							<th class="text-bold">{{ $t("manufacturerDealer") }}</th> -->
						</tr>
						<tr>
							<th>
								{{ $t("active") }}
							</th>
							<th>
								{{ $t("all") }}
							</th>
                            <th>
								{{ $t("active") }}
							</th>
							<th>
								{{ $t("all") }}
							</th>
						</tr>
                        
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableContent" :key="index" class="text-center">
							<td class="text-left">
								{{ item.companyName | translate }}
							</td>
							<td>
								{{ item.tin }}
							</td>
							<td>
								{{ formatDate(item.registeredDate) }}
							</td>
							<td>
								{{ item.regionName | translate }}
							</td>
							<td>
								{{ item.districtName | translate }}
							</td>
							<td>
								{{ item.isBudget ? $t("budget") : $t("corporative") }}
							</td>
							<td>
								{{ item.isCustomer ? $t("customer2") : $t("") }}{{ item.isCustomer && item.isCorp ? $t("/") : $t("") }}{{ item.isCorp ? $t("supplier") : $t("") }}
							</td>
							<!-- <td>
								{{ item.isCorp ? $t("supplier") : $t("-") }}
							</td> -->
							<td>
								{{ item.currentActiveProductsCountCorp }}
							</td>
							<td>
								{{ item.allActiveProductsCountCorp }}
							</td>
                            <td>
								{{ item.isDiler ? $t("dealer") : $t("-") }}
							</td>
                            <td>
								{{ item.currentActiveProductsCountDiler }}
							</td>
							<td>
								{{ item.allActiveProductsCountDiler }}
							</td>
							<!-- <td>
								{{ companyTypesNames[item.participantType] || "-" }}
							</td> -->
							<!-- <td>
								{{ item.manufacturer || "-" }}
							</td>
							<td>
								{{ item.manufacturerOrDealer || "-" }}
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>

			<pagination
				:total="this.pageConfig.elementsCount"
				:perPage="this.pageConfig.selectedCount"
				@paginate="toPage($event)"
			></pagination>
		</div>
	</main>
</template>

<script>
import xlsx from "json-as-xlsx";
import AppText from "../../components/shared-components/AppText";
import AppInput from "../../components/shared-components/AppInput";
import AppButton from "../../components/shared-components/AppButton";
import AppPagination from "../../components/shared-components/AppPagination";
import Pagination from "../../components/shared-components/Pagination";
import Switch from "../../components/shared-components/Switch";
import { companyTypesNames } from "@/static/statusIds";
import FormLabel from "../../components/shared-components/FormLabel";
import AppMultipleSelect from "../../components/shared-components/AppMultipleSelect";

export default {
	name: "index",
	components: {
		AppPagination,
		AppButton,
		AppInput,
		AppText,
		Pagination,
		"app-switch": Switch,
		FormLabel,
		AppMultipleSelect,
	},
	data() {
		return {
			regionsList: [],
			allRegionsList: [],
			districtsList: [],
			allDistrictList: [],
			pageConfig: {
				filters: {
					tin: null,
					isAny: false,
					regionId: null,
					districtIds: [],
				},
				activePage: 1,
				elementsCount: 0,
				selectedCount: 15,
			},
			tableContent: [],
			companyTypesNames,
		};
	},
	async created() {
		await this.toPage();
		await this.getAllRegions();
		await this.getAllDistricts();
	},
	methods: {
		pickDistricts(val) {
			if (Array.isArray(val)) {
				this.pageConfig.filters.districtIds = val.map((e) => e.value);
			} else {
				if (!this.pageConfig.filters.districtIds.includes(val.value)) {
					this.pageConfig.filters.districtIds.push(val.value);
					this.pageConfig.filters.districtIds = [...new Set([...this.pageConfig.filters.districtIds])];
				} else {
					let index = this.pageConfig.filters.districtIds.findIndex((e) => e === val.value);
					this.pageConfig.filters.districtIds.splice(index, 1);
					this.pageConfig.filters.districtIds = [...new Set([...this.pageConfig.filters.districtIds])];
				}
			}
		},
		searchRegion(val) {
			this.pageConfig.filters.regionId = null;
			this.pageConfig.filters.districtIds = [];
			// console.log(this.allRegionsList[this.$store.state.clng]);
			let result = this.allRegionsList.filter((e) =>
				e.name[this.$store.state.clng].toUpperCase().includes(val.toUpperCase())
			);
			this.regionsList = result;
		},
		searchDistrict(val) {
			this.pageConfig.filters.districtIds = [];
			if (this.pageConfig.filters.regionId && this.allDistrictList.length) {
				let result = this.allDistrictList.filter((e) =>
					e.name[this.$store.state.clng].toUpperCase().includes(val.toUpperCase())
				);
				this.districtsList = result;
			}
		},
		async getAllRegions() {
			const response = await this.$api.get("/api-admin/Reference/GetAllRegion?skip=0&take=100");
			this.allRegionsList = response.data.result.data.map((e) => {
				return {
					name: e.name,
					value: e.id,
				};
			});
			this.regionsList = response.data.result.data.map((e) => {
				return {
					name: e.name,
					value: e.id,
				};
			});
		},
		async getAllDistricts() {
			const response = await this.$api.get("/api-admin/Reference/GetAllDistrict?skip=0&take=1000");
			this.allDistrictList = response.data.result.data.map((e) => {
				return { name: e.name, value: e.id, regionId: e.regionId };
			});
		},
		search() {
			this.toPage();
		},
		repeat() {
			this.pageConfig = {
				filters: {
					regionId: null,
					districtIds: [],
					tin: null,
				},
				activePage: 1,
				elementsCount: 0,
				selectedCount: 15,
			};
			this.toPage();
		},
		async downloadExcel() {
			try {
				this.$store.commit("setLoadingStatus", true);
				let data = {
					...this.pageConfig.filters,
					skip: 0,
					take: 100000,
				};
				let res = [];
				const response = await this.$api.post(
					`/api-client/Register/GetAllCompanies`,
					data
				);
				res = response.data.result.data;
				let settings = { fileName:  this.$t("getAllCompanies")  },
					xlsxData = [],
					sheet =  "" ,
					column = [
						{
							label: this.$t("organizationName"),
							value: (row) => (row.companyName ? row.companyName["uz"] : "-"),
						},
						{
							label: this.$t("inn"),
							value: "tin",
						},
						{
							label: this.$t("date"),
							value: (row) => moment(row.registeredDate).format("DD.MM.YYYY"),
						},
						{
							label: this.$t("region"),
							value: (row) => (row.regionName ? row.regionName["uz"] : "-"),
						},
						{
							label: this.$t("district"),
							value: (row) => (row.districtName ? row.districtName["uz"] : "-"),
						},
						{
							label: this.$t("typeOrganization"),
							value: (row) => (row.isBudget ? this.$t("budget") : this.$t("corporative")),
						},
						{
							label: this.$t("providerOrCustomer"),
							value: (row) => (row.isCustomer ? this.$t("customer2") : this.$t("")) + (row.isCorp && row.isCustomer ? this.$t("/") : this.$t("")) + (row.isCorp ? this.$t("supplier") : this.$t("")),
						},
						{
							label: this.$t("numberOfOffersByCorp") + " (" + this.$t("active") + ")",
							value: "currentActiveProductsCountCorp",
						},
						{
							label: this.$t("numberOfOffersByCorp") + " (" + this.$t("all") + ")",
							value: "allActiveProductsCountCorp",
						},
						{
							label: this.$t("dealer"),
							value: (row) => (row.isDiler ? this.$t("dealer") : this.$t("-")),
						},
                        {
							label: this.$t("numberOfOffersByDiler") + " (" + this.$t("active") + ")",
							value: "currentActiveProductsCountDiler",
						},
						{
							label: this.$t("numberOfOffersByDiler") + " (" + this.$t("all") + ")",
							value: "allActiveProductsCountDiler",
						},
					];
				xlsxData.push({
					sheet: sheet,
					columns: column,
					content: res,
				});
				xlsx(xlsxData, settings);
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		async toPage(page = this.pageConfig.selectedCount, pageCount = this.pageConfig.selectedCount) {
			let data = {
				...this.pageConfig.filters,
				skip: page - pageCount,
				take: pageCount,
			};
			this.$store.commit("setLoadingStatus", true);
			try {
				const response = await this.$api.post(
					`/api-client/Register/GetAllCompanies`,
					data
				);
				this.tableContent = response.data.result.data;
				this.pageConfig.elementsCount = response.data.result.total;
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		formatDate(date) {
			return moment(date).format("DD.MM.YYYY");
		},
	},
	watch: {
		"pageConfig.filters.regionId"(val) {
			this.pageConfig.filters.districtIds = [];
			this.districtsList = this.allDistrictList.filter((e) => {
				if (e.regionId === val) return e;
			});
		},
	},
};
</script>

<style>
.gridForSection {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 60px 60px 70px;
	gap: 10px;
}
.filter__search .base-input {
	padding-left: 5px !important;
}
</style>
